import React, { useEffect } from 'react'
import { Step, Stepper } from 'react-form-stepper';
import PersonalDetails from './resume/personalDetails';
import PreviousEmployers from './resume/prevEmployers';
import TrainingAndDevelopment from './resume/trainingAndDevelopment';
import Rehabilitation from './resume/rehabilitation';
import ProtectingAdultsAndChildren from './resume/protecting';
import References from './resume/references';
import RecruitmentMonitoring from './resume/recruitmentMonitoring';
import Declaration from './resume/declaration';
import { v4 as uuidv4 } from 'uuid';
import GeneratedUUID from './partials/mainUuid';
import Uploads from './resume/uploads';


const Registeration = () => {
  const [selected, setSelected] = React.useState(7)
  const [sections, setSections] = React.useState([{ id: 1 }]);
  const [trainingSections, setTrainingSections] = React.useState([{ id: 1 }]);
  const [refereeSections, setRefereeSections] = React.useState([{ id: 1 }]);
  const newUserId = GeneratedUUID;
  const [newUser, setNewUser] = React.useState(newUserId);

  const addSection = () => {
    setSections([...sections, { id: sections.length + 1 }]);
  };
  const addTrainingSection = () => {
    setTrainingSections([...trainingSections, { id: trainingSections.length + 1 }]);
  };
  const addReferrenceSection = () => {
    setRefereeSections([...refereeSections, { id: refereeSections.length + 1 }]);
  };



  const theForm = (selected, newUser) => {

    switch (selected) {
      case 0:
        return (
          <PersonalDetails next={() => setSelected(1)} newUser={newUser} />
        );


      case 1:
        return (
          <PreviousEmployers
            addSection={() => addSection()}
            sections={sections}
            prev={() => setSelected(0)}
            next={() => setSelected(2)}
            newUser={newUser}
          />
        );


      case 2:
        return (

          <TrainingAndDevelopment
            trainingSections={trainingSections}
            addTrainingSection={() => addTrainingSection()}
            prev={() => setSelected(1)}
            next={() => setSelected(3)}
            newUser={newUser}
          />

        );

      case 3:
        return (

          <Rehabilitation
            prev={() => setSelected(2)}
            next={() => setSelected(4)}
            newUser={newUser}
          />

        );

      case 4:
        return (
          <ProtectingAdultsAndChildren
            prev={() => setSelected(3)}
            next={() => setSelected(5)}
            newUser={newUser}
          />
        );


      case 5:
        return (
          <References
            refereeSections={refereeSections}
            addReferrenceSection={() => addReferrenceSection()}
            prev={() => setSelected(4)}
            next={() => setSelected(6)}
            newUser={newUser}
          />
        );


      case 6:
        return (
          <RecruitmentMonitoring
            prev={() => setSelected(5)}
            next={() => setSelected(7)}
            newUser={newUser}
          />
        );

      case 7:
        return (
          <Uploads
            prev={() => setSelected(6)}
            next={() => setSelected(8)}
            newUser={newUser}
          />

        );

      case 8:
        return (
          <Declaration
            prev={() => setSelected(7)}
            newUser={newUser}
          />
        );

      default:
        return (
          <div className="col-lg-12 form_col">
            <PersonalDetails
              setNext={setSelected(1)}
              newUser={newUser}
            />
          </div>
        )

    }
  }

  useEffect(() => {
    setSelected(0);
  }, [])

  return (
    <div>
      <Stepper activeStep={selected}>
        <Step label="PERSONAL DETAILS" />
        <Step label="PREVIOUS EMPLOYMENT" />
        <Step label="TRAINING AND DEVELOPMENT" />
        <Step label="REHABILITATION OF OFFENDERS ACT" />
        <Step label="PROTECTING CHILDREN & VULNERABLE ADULTS" />
        <Step label="REFERENCES" />
        <Step label="RECRUITMENT MONITORING" />
        <Step label="UPLOADS" />
        <Step label="DECLARATION" />

      </Stepper>
      <div className="form-container py-5"
        style={{
          backgroundColor: '#FBFEF9'
          // backgroundColor: 'grey'
        }}
      >
        {
          theForm(selected, newUser)
        }
      </div>

    </div>
  )
}

export default Registeration