const images = {
  about: require('./about.jpg'), // Adjust path as needed
  ad: require('./ad.jpg'),
  alarmClock: require('./alarm-clock.svg'),
  bell: require('./bell.svg'),
  dept_1: require('./dept_1.jpg'),
  dept_2: require('./dept_2.jpg'),
  dept_3: require('./dept_3.jpg'),
  elderCare: require('./elder-care.png'),
  envelope: require('./envelope.svg'),
  feature_1: require('./services/home-care.jpeg'),
  feature_2: require('./services/two-black-elderly.avif'),
  feature_3: require('./services/live-in-care.jpg'),
  feature_4: require('./services/home-care.jpg'),
  feature_5: require('./services/respite-care.webp'),
  feature_6: require('./services/flexible-schedule.jpg'),
  home_background_1: require('./home_background_1.jpg'),
  happyElders: require('./happy-elders.jpg'),
  logo: require('./logo.jpg'),
  mainBackground: require('./main-background.png'),
  news_1: require('./news_1.jpg'),
  news_2: require('./news_2.jpg'),
  news_3: require('./news_3.jpg'),
  phoneCall: require('./phone-call.svg'),
  placeholder: require('./placeholder.svg'),
  quote: require('./quote.png'),
  resumeBg: require('./resume-bg.png'),
  search: require('./search.png'),
  service_1: require('./service_1.svg'),
  service_2: require('./service_2.svg'),
  service_3: require('./service_3.svg'),
  service_4: require('./service_4.svg'),
  service_5: require('./service_5.svg'),
  service_6: require('./service_6.svg'),
  team_1: require('./team_1.png'),
  team_2: require('./team_2.png'),
  team_3: require('./team_3.png'),
  team_4: require('./team_4.png'),
  test_1: require('./test_1.jpg'),
  test_2: require('./test_2.jpg'),
  text_section: require('./text_section.jpg'),
  menu: require('./menu.png'),
};

export default images;