import React from 'react'
import { NavLink } from 'react-router-dom'
import images from '../../assets/images/images';
import Menu from './menu';

const Header = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div>
      <div className="mobile-menu sticky-top" style={{
        position: 'relative',
        zIndex: 100
      }}>
        <div className="d-md-none d-flex py-1 text-center" style={{
          backgroundColor: '#4B0082'
        }}>

        </div>
        <div className="d-md-none d-flex py-1 text-center px-2" style={{
          backgroundColor: 'whitesmoke'
        }}>
          <div className="d-flex justify-content-between w-100">
            <div className="logo-side" style={{
              width: "50px"
            }}>
              <img src={images.logo} alt="" srcset="" className='img-fluid' />
            </div>

            <div className="logo-side" style={{
              width: "30px"
            }}
              onClick={() => setModalShow(true)}
            >
              <img src={images.menu} alt="" srcset="" className='img-fluid' />
            </div>
          </div>
        </div>
      </div>

      <header class="header trans_200 d-none d-md-block shadow-sm" >

        {/* <!-- Top Bar --> */}
        <div class="top_bar">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="top_bar_content d-flex flex-row align-items-center justify-content-between">
                  <div class="details d-flex">
                    <div class="top_bar_item">
                      <a href="https://wa.me/0749620643" target="_blank">Chat on
                        WhatsApp</a>
                    </div>
                    <div class="top_bar_item"><a href="#">Call: + 0151 271
                      5767</a></div>

                    <div class="top_bar_item"><a href="#">Request an Appointment</a></div>
                  </div>
                  <div style={{ cursor: "pointer" }}
                    class="emergencies  d-flex flex-row align-items-center justify-content-start ml-auto">
                    <NavLink to='/join' style={{ color: 'white' }}>
                      Join Our Team
                    </NavLink>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* <!-- Header Content --> */}
        <div class="header_container">
          <div class="container">
            <div class="row">

              <div class="col">

                <div class="header_content d-flex flex-row align-items-center justify-content-between">
                  <div>
                    <img src={images.logo} alt="" srcset="" style={{ width: "22%", height: "7%" }} />
                  </div>
                  <nav class="main_nav ml-auto  w-100 d-none d-md-flex justify-content-end">
                    <ul>
                      <li><NavLink to="/" aria-current="page">Home</NavLink></li>
                      <li><NavLink to="/about">About us</NavLink></li>
                      <li><NavLink to="/services">Services</NavLink></li>
                      <li><NavLink to="/contact">Contact</NavLink></li>
                    </ul>
                  </nav>
                  <div class="hamburger ml-auto"><i class="fa fa-bars" aria-hidden="true"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Logo --> */}


      </header>
      <Menu
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  )
}

export default Header