import React from 'react'
import Swal from 'sweetalert2'

const Declaration = (props) => {

  const successMessage = ()=>{
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Your Application has been submitted successfully!",
      showConfirmButton: false,
      timer: 2500
    });


  }

  return (
    <div class="col-lg-12 form_col ">
    <div class="contact_form_container">
      <form action="#" id="contact_form" class="contact_form px-5">

        <h3>By clicking the submit button to this application form, I certify that:</h3>
        <p>
          all the information given by me on this form is correct to the best of my knowledge
          all questions relating to me have been accurately and fully answered
          I possess all the qualifications which I claim to hold
          I have read and, if appointed, am prepared to accept the conditions set out in the conditions of employment and the job description.
        </p>
        <div className="d-flex justify-content-end pt-4 me-4">
         
          <button class="button contact_button"
           onClick={successMessage}
          >
            <a href="#">Submit</a>
          </button>
        </div>

        <br />
        <button class="button contact_button"
            onClick={props.prev}
          >
            <a href="#">Back</a>
          </button>
      </form>
    </div>
  </div>
  )
}

export default Declaration