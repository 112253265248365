import { TextField } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const PreviousEmployers = (props) => {
  const [sections, setSections] = React.useState([{
    id: '1', // You may replace this with a unique id generator if needed
    name: '',
    address: '',
    position: '',
    duties: '',
    reasonForLeaving: '',
    joinDate: '',
    exitDate: ''
  }]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newSections = sections.slice();
    newSections[index][name] = value;
    setSections(newSections);
  };

  const addSection = () => {
    setSections([...sections, {
      id: (sections.length + 1).toString(),
      name: '',
      address: '',
      position: '',
      duties: '',
      reasonForLeaving: '',
      joinDate: '',
      exitDate: ''
    }]);
  };

  const handleSubmit = async () => {
    const payload = sections.map(section => ({
      id: uuidv4(),
      name: section.name,
      address: section.address,
      position: section.position,
      duties: section.duties,
      reasonForLeaving: section.reasonForLeaving,
      joinDate: section.joinDate,
      exitDate: section.exitDate,
      personalDetailId: props.newUser // Replace with actual personalDetailId
    }));

    try {
      const response = await fetch('https://api.rhaegalcare.co.uk/api/Employees/AddEmploymentHistory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Success:', responseData);

      props.next(); // Call the next function passed in props
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="contact_form_container">
      <form id="contact_form" className="contact_form px-5">
        {sections.map((section, index) => (
          <div className="" key={section.id}>
            <div className="d-flex w-100 mx-2">

              <TextField
                id="filled-basic"
                label="Name of Employer"
                variant="filled"
                name="name"
                value={section.name}
                onChange={(e) => handleInputChange(index, e)}
                className='w-100 me-1 mb-3'
                size='small'
                required
              />




              <TextField
                id="filled-basic"
                label="Address"
                variant="filled"
                name="name"
                value={section.address}
                onChange={(e) => handleInputChange(index, e)}
                className='w-100 ms-1 mb-3'
                size='small'
                required
              />



            </div>


            <TextField
              id="filled-basic"
              label="Position Held"
              variant="filled"
              name="position"
              value={section.position}
              onChange={(e) => handleInputChange(index, e)}
              className='w-100 ms-1 mb-3'
              size='small'
              required
            />
            {/* <input
                  type="text"
                  className="contact_input"
                  placeholder="Position Held"
                  name="position"
                  value={section.position}
                  onChange={(e) => handleInputChange(index, e)}
                  required
                /> */}




            <TextField
              id="filled-multiline-flexible"
              label="Summary of Duties"
              multiline
              maxRows={4}
              variant="filled"
              className='w-100 ms-1 mb-3'
              name="duties"
              value={section.duties}
              onChange={(e) => handleInputChange(index, e)}
              required
            />
            {/* <input
                  type="text"
                  className="contact_input"
                  placeholder="Summary of Duties"
                  name="duties"
                  value={section.duties}
                  onChange={(e) => handleInputChange(index, e)}
                  required
                /> */}




            {/* <input
                  type="text"
                  className="contact_input"
                  placeholder="Reason for Leaving"
                  name="reasonForLeaving"
                  value={section.reasonForLeaving}
                  onChange={(e) => handleInputChange(index, e)}
                  required
                /> */}
            <TextField
              id="filled-multiline-flexible"
              label="Reason for Leaving"
              multiline
              maxRows={4}
              variant="filled"
              className='w-100 ms-1 mb-3'
              name="reasonForLeaving"
              value={section.reasonForLeaving}
              onChange={(e) => handleInputChange(index, e)}
              required
            />




          </div>
        ))}
        <div className="d-flex justify-content-center pt-4">
          <button type="button" className="button contact_button" onClick={addSection}>
            <a href="#">+ Add Another Employer</a>
          </button>
        </div>
        <div className="d-flex justify-content-between pt-4">
          <button type="button" className="button contact_button" onClick={props.prev}>
            <a>Back</a>
          </button>
          <button type="button" className="button contact_button" onClick={() => { handleSubmit(); props.next(); }}>
            <a>Next</a>
          </button>
        </div>
      </form>
    </div>
  );
}

export default PreviousEmployers;