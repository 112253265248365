import React from 'react'
import servOne from '../assets/images/icons/command.svg'
import servTwo from '../assets/images/icons/gift.svg'
import servThree from '../assets/images/icons/link.svg'
import servFour from '../assets/images/icons/pen-tool.svg'
import servFive from '../assets/images/icons/trending-up.svg'
import servSix from '../assets/images/icons/umbrella.svg'

const Values = () => {
  return (
    <div class="services">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="section_title">
              <h2>Our Core Values</h2>
            </div>
          </div>
        </div>
        <div class="row services_row">

          {/* <!-- Service --> */}
          <div class="col-lg-4 col-md-6 service_col">
            <a href="services.html">
              <div class="service text-center trans_200">
                <div class="service_icon"><img class="svg" src={servTwo} alt="" /></div>
                <div class="service_title trans_200">Compassion</div>
                <div class="service_text">
                  <p>We care deeply about our clients and approach every situation with empathy and understanding.</p>
                </div>
              </div>
            </a>
          </div>

          {/* <!-- Service --> */}
          <div class="col-lg-4 col-md-6 service_col">
            <a href="services.html">
              <div class="service text-center trans_200">
                <div class="service_icon"><img class="svg" src={servOne} alt="" /></div>
                <div class="service_title trans_200">Respect</div>
                <div class="service_text">
                  <p>We treat each individual with dignity and respect, honoring their preferences and choices</p>
                </div>
              </div>
            </a>
          </div>

          {/* <!-- Service --> */}
          <div class="col-lg-4 col-md-6 service_col">
            <a href="services.html">
              <div class="service text-center trans_200">
                <div class="service_icon"><img class="svg" src={servThree} alt="" /></div>
                <div class="service_title trans_200">Integrity</div>
                <div class="service_text">
                  <p>We operate with honesty and transparency, building trust with our clients and their families</p>
                </div>
              </div>
            </a>
          </div>

          {/* <!-- Service --> */}
          <div class="col-lg-4 col-md-6 service_col">
            <a href="services.html">
              <div class="service text-center trans_200">
                <div class="service_icon"><img class="svg" src={servFive} alt="" /></div>
                <div class="service_title trans_200">Excellence</div>
                <div class="service_text">
                  <p>We are dedicated to providing the highest standard of care through continuous improvement and
                    professional development.</p>
                </div>
              </div>
            </a>
          </div>

          {/* <!-- Service --> */}
          <div class="col-lg-4 col-md-6 service_col">
            <a href="services.html">
              <div class="service text-center trans_200">
                <div class="service_icon"><img class="svg" src={servFour} alt="" /></div>
                <div class="service_title trans_200">Personalization:</div>
                <div class="service_text">
                  <p>We tailor our services to meet the specific needs and goals of each client, ensuring they receive
                    the best possible
                    care.</p>
                </div>
              </div>
            </a>
          </div>

          {/* <!-- Service --> */}
          <div class="col-lg-4 col-md-6 service_col">
            <a href="services.html">
              <div class="service text-center trans_200">
                <div class="service_icon"><img class="svg" src={servSix} alt="" /></div>
                <div class="service_title trans_200">Honesty</div>
                <div class="service_text">
                  <p>We operate with honesty and transparency, building trust with our clients and their families.</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Values