import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

const RecruitmentMonitoring = (props) => {
  const [formData, setFormData] = useState({
    ethnicGroup: '',
    gender: '',
    hasDisability: false,
    personalDetailId: props.newUser // This should be dynamically set if needed
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'radio' ? value : checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.rhaegalcare.co.uk/api/Employees/AddEthnicity', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Success:', response.data);
      // Call the next function passed in props if needed
      props.next();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="col-lg-12 form_col">
      <div className="contact_form_container">
        <form className="contact_form px-5" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 input_col">
              <p>What is your Ethnic Group?</p>
              <div className="mb-3 d-flex flex-column">
                <Form.Check
                  inline
                  label="A. White"
                  name="ethnicGroup"
                  type="radio"
                  id="ethnicGroup1"
                  value="White"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="B. Mixed"
                  name="ethnicGroup"
                  type="radio"
                  id="ethnicGroup2"
                  value="Mixed"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="C. Asian or Asian British"
                  name="ethnicGroup"
                  type="radio"
                  id="ethnicGroup3"
                  value="Asian or Asian British"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="D. Black or Black British"
                  name="ethnicGroup"
                  type="radio"
                  id="ethnicGroup4"
                  value="Black or Black British"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="E. Chinese or other ethnic group"
                  name="ethnicGroup"
                  type="radio"
                  id="ethnicGroup5"
                  value="Chinese or other ethnic group"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="F. I do not wish to provide this information"
                  name="ethnicGroup"
                  type="radio"
                  id="ethnicGroup6"
                  value="I do not wish to provide this information"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 input_col">
              <p>Gender (Required)</p>
              <div className="mb-3 d-flex flex-column">
                <Form.Check
                  inline
                  label="Male"
                  name="gender"
                  type="radio"
                  id="gender1"
                  value="Male"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="Female"
                  name="gender"
                  type="radio"
                  id="gender2"
                  value="Female"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="Non Binary"
                  name="gender"
                  type="radio"
                  id="gender2"
                  value="NonBinary"
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="Prefer not to say"
                  name="gender"
                  type="radio"
                  id="gender2"
                  value="N/A"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 input_col">
              <p>Disability (Required)</p>
              <div className="mb-3 d-flex flex-column">
                <Form.Check
                  inline
                  label="Yes"
                  name="hasDisability"
                  type="radio"
                  id="disability1"
                  value={true}
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  label="No"
                  name="hasDisability"
                  type="radio"
                  id="disability2"
                  value={false}
                  onChange={handleInputChange}
                />
              </div>
              <p>
                Disability is defined as “physical or mental impairment, which
                has a substantial and long term adverse effect on a person’s
                ability to carry out normal day to day activities”.
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between pt-4">
            <button className="button contact_button" onClick={props.prev}>
              <a href="#">Back</a>
            </button>

            <button className="button contact_button" type="submit" onClick={(e) => { handleSubmit(e); props.next(); }}>
              <a href="#">Next</a>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecruitmentMonitoring;
