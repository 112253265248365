import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';

const Menu = (props) => {

  const color = {
    color: "#4B0082"
  }

  return (
    <Modal
      {...props}
      size="lg"
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rhaegal Care
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NavLink to="/" ><h4 style={color}>Home</h4></NavLink>
        <NavLink to="/about" ><h4 style={color}>About Us</h4></NavLink>
        <NavLink to="/services" ><h4 style={color}>Our Services</h4></NavLink>
        <NavLink to="/contact" ><h4 style={color}>Contact Us</h4></NavLink>
        <NavLink to="/join" ><h4 style={color}>Join Us</h4></NavLink>


      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Menu