import { TextField } from '@mui/material';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'

const PersonalDetails = (props) => {
  const [selectedRole, setSelectedRole] = React.useState(null)
  const [formData, setFormData] = React.useState({
    id: '', // Replace this with a dynamically generated UUID if needed
    firstName: '',
    lastName: '',
    address: '',
    addressLineTwo: '',
    city: '',
    county: '',
    postcode: '',
    phoneNumber: '',
    emailAddress: '',
    role: '',
    eligibilityToWork: '',
    accessToOwnWork: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {

    // Assign the new user ID to formData
    const updatedFormData = {
      ...formData,
      id: props.newUser,
      role: selectedRole,
      eligibilityToWork: true,
      accessToOwnWork: true
    };

    // Check if form data values are not empty
    const isFormValid = Object.values(updatedFormData).every(value => value !== '');

    if (!isFormValid) {
      console.error('Error: Some form fields are empty.');
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error: Please fill in all required fields!",
        footer: '<a href="#">Why do I have this issue?</a>'
      });
      // Show an error message to the user
      // alert('All form fields are required.');

      return;
    }
    else {
      try {
        const response = await fetch('https://api.rhaegalcare.co.uk/api/Employees/AddPersonalDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updatedFormData)
        });
        props.next();
        if (!response.ok) {
          throw new Error('Network response was not ok');

        }

        const responseData = await response.json();
        console.log('Success:', responseData);

        // Call the next function passed in props
      } catch (error) {
        console.error('Error:', error);
      }
    }

  };


  return (
    <div className="col-lg-12 form_col">
      <div className="contact_form_container">
        <form className="contact_form px-5" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <div className="row">
            <div className="col-md-12 input_col mb-3">
              <Form.Select aria-label="Default select example" className="input_container input_name py-3" onChange={(e) => setSelectedRole(e.target.value)}>
                <option className="input_container input_name">Select role you are applying for:</option>
                <option value="Senior Care Assistant" className="input_container input_name">Senior Care Assistant</option>
                <option value="Live in Care" className="input_container input_name">Live in Care</option>
                <option value="Healthcare Assistant" className="input_container input_name">Healthcare Assistant</option>
              </Form.Select>
            </div>
            <div className="col-md-6 input_col">
              <div className="input_container input_name">
                {/* <input
                  type="text"
                  className="contact_input"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required="required"
                /> */}
                <TextField
                  id="filled-basic"
                  label="First Name"
                  variant="filled"
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className='w-100'
                  size='small'
                  required
                />
              </div>
            </div>
            <div className="col-md-6 input_col">
              <div className="input_container">
                {/* <input
                  type="text"
                  className="contact_input"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required="required"
                /> */}
                <TextField
                  id="filled-basic"
                  label="Last Name"
                  variant="filled"
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className='w-100'
                  size='small'
                  required
                />
              </div>
            </div>
          </div>
          {/* Add inputs for phoneNumber and emailAddress */}
          <div className="input_container">
            {/* <input
              type="text"
              className="contact_input"
              placeholder="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required="required"
            /> */}
            <TextField
              id="filled-basic"
              label="Phone Number"
              variant="filled"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className='w-100'
              size='small'
              required
            />
          </div>
          <div className="input_container">
            {/* <input
              type="email"
              className="contact_input"
              placeholder="Email Address"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
              required="required"
            /> */}
            <TextField
              id="filled-basic"
              label="Email Address"
              variant="filled"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
              className='w-100'
              size='small'
              required
            />
          </div>
          <div className="input_container">
            {/* <input
              type="text"
              className="contact_input"
              placeholder="Address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              required="required"
            /> */}
            <TextField
              id="filled-basic"
              label="Address"
              variant="filled"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className='w-100'
              size='small'
              required
            />
          </div>
          <div className="input_container">
            {/* <input
              type="text"
              className="contact_input"
              placeholder="Address Line 2"
              name="addressLineTwo"
              value={formData.addressLineTwo}
              onChange={handleInputChange}
              required="required"
            /> */}
            <TextField
              id="filled-basic"
              label="Address Line 2"
              variant="filled"
              name="addressLineTwo"
              value={formData.addressLineTwo}
              onChange={handleInputChange}
              className='w-100'
              size='small'
              required
            />
          </div>
          <div className="row">
            <div className="col-md-6 input_col">
              <div className="input_container input_name">
                {/* <input
                  type="text"
                  className="contact_input"
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required="required"
                /> */}
                <TextField
                  id="filled-basic"
                  label="City"
                  variant="filled"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className='w-100'
                  size='small'
                  required
                />
              </div>
            </div>
            <div className="col-md-6 input_col">
              <div className="input_container">
                <TextField
                  id="filled-basic"
                  label="County"
                  variant="filled"
                  name="county"
                  value={formData.county}
                  onChange={handleInputChange}
                  className='w-100'
                  size='small'
                  required
                />
                {/* <input
                  type="text"
                  className="contact_input"
                  placeholder="County"
                  name="county"
                  value={formData.county}
                  onChange={handleInputChange}
                  required="required"
                /> */}
              </div>
            </div>
          </div>
          <div className="input_container">
            <TextField
              id="filled-basic"
              label="Postcode"
              variant="filled"
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              className='w-100'
              size='small'
              required
            />
            {/* <input
              type="text"
              className="contact_input"
              placeholder="Postcode"
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              required="required"
            /> */}
          </div>

          {/* Add checkboxes for eligibilityToWork and accessToOwnWork */}
          <p>Are you eligible to work in the UK? (Required)</p>
          <div className="mb-3">
            <Form.Check
              inline
              label="Yes"
              name="eligibilityToWork"
              type="radio"
              id="eligibilityToWork-yes"
              value="Yes"
              checked={formData.eligibilityToWork === 'Yes'}
              onChange={(e) => setFormData({ ...formData, eligibilityToWork: e.target.value })}
            />
            <Form.Check
              inline
              label="No"
              name="eligibilityToWork"
              type="radio"
              id="eligibilityToWork-no"
              value="No"
              checked={formData.eligibilityToWork === 'No'}
              onChange={(e) => setFormData({ ...formData, eligibilityToWork: e.target.value })}
            />
          </div>


          <Form.Group controlId="formFileDrivingLicense" className="mb-3">
            <Form.Label>Attach a Valid driving licence</Form.Label>
            <Form.Control type="file"
            // onChange={(e) => handleFileChange(e, 'drivingLicense')} 
            />
          </Form.Group>

          <Form.Group controlId="formFileEligibility" className="mb-3">
            <Form.Label>Attach Proof of eligibility to work (Please attach Work Visa, COS, BRP Employment and Authorization Document)</Form.Label>
            <Form.Control type="file"
            // onChange={(e) => handleFileChange(e, 'eligibility')} 
            />
          </Form.Group>

          <div className="d-flex justify-content-end pt-4 px-4">
            <button
              className="button contact_button"
              type="submit"
              onClick={() => { handleSubmit() }}
            >
              <a>Next</a>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetails;
