import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Swal from 'sweetalert2'

const Uploads = (props) => {
  const [isUploadInitiated, setIsUploadInitiated] = useState(false)
  const [files, setFiles] = useState({
    governmentPhoto: null,
    niDocument: null,
    proofOfAddress: null,
    resume: null,
    certificates: null,
    eligibility: null,
    education: null,
    dbs: null,
    drivingLicense: null,
  });

  const handleFileChange = (e, key) => {
    setFiles({
      ...files,
      [key]: e.target.files[0], // only one file per input
    });
  };

  const confirmUploadStatus = () => {
    if (isUploadInitiated === false) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error: Please add all required documents and upload!",
        footer: '<a href="#">Why do I have this issue?</a>'
      });
    }

    else {
      props.next();
    }
  }
  const uploadFile = async (file, tag) => {
    const formData = new FormData();
    formData.append('images', file);
    formData.append('Tag', tag);
    formData.append('personalDetailId', props.newUser);

    try {
      const response = await axios.post('https://api.rhaegalcare.co.uk/api/Uploads/UploadDocument', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',

        }
      });
      console.log(`${tag} upload successful`, response.data);
      return response.data; // return response for logging or further processing
    } catch (error) {
      console.error(`${tag} upload error`, error);
      throw error; // propagate error for handling in Promise.all
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uploadTasks = [];

    Object.keys(files).forEach(key => {
      if (files[key]) {
        const tag = key; // Capitalize and format key
        uploadTasks.push(uploadFile(files[key], tag));
      }
    });

    try {
      const results = await Promise.all(uploadTasks);
      console.log('All uploads successful', results);
      Swal.fire({
        icon: "success",
        title: "Documents uploaded successfully!",
        showConfirmButton: false,
        timer: 1500
      });
      setIsUploadInitiated(true);
      props.next();
    } catch (error) {
      console.error('One or more uploads failed', error);
    }
  };

  return (
    <div className="col-lg-12 form_col">
      <div className="contact_form_container">
        <form id="contact_form" className="contact_form px-5" onSubmit={handleSubmit}>

          <h3>Uploads</h3>
          <hr />
          <Form>
            <Form.Group controlId="formFileGovernmentPhoto" className="mb-3">
              <Form.Label>1. Government Issued Photo</Form.Label>
              <Form.Control type="file" onChange={(e) => handleFileChange(e, 'governmentPhoto')} />
            </Form.Group>

            <Form.Group controlId="formFileNiDocument" className="mb-3">
              <Form.Label>2. NI Document or proof</Form.Label>
              <Form.Control type="file" onChange={(e) => handleFileChange(e, 'niDocument')} />
            </Form.Group>

            <Form.Group controlId="formFileProofOfAddress" className="mb-3">
              <Form.Label>3. Two Proof of address (e.g Utility Bill, Bank Statement, Lease Agreement)</Form.Label>
              <Form.Control type="file" onChange={(e) => handleFileChange(e, 'proofOfAddress')} />
            </Form.Group>

            <Form.Group controlId="formFileResume" className="mb-3">
              <Form.Label>4. Updated Resume</Form.Label>
              <Form.Control type="file" onChange={(e) => handleFileChange(e, 'resume')} />
            </Form.Group>

            <Form.Group controlId="formFileEducation" className="mb-3">
              <Form.Label>5. Proof of your education (e.g. ELTS, GCSE or equivalent, Degree)</Form.Label>
              <Form.Control type="file" onChange={(e) => handleFileChange(e, 'education')} />
            </Form.Group>




          </Form>

          <div className="mb-5 py-5 d-flex w-100 justify-content-center">
            <button type="submit" className="button contact_button" onClick={(e) => { handleSubmit(e) }}>
              <a href="#">Upload</a>
            </button>
          </div>
          <div className="d-flex justify-content-between pt-4">
            <button type="button" className="button contact_button" onClick={props.prev}>
              <a href="#">Back</a>
            </button>
            <button type="button" className="button contact_button" onClick={() => { confirmUploadStatus() }}>
              <a href="#">Next</a>
            </button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default Uploads;
