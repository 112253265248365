import React from 'react'
import images from '../assets/images/images';
import '../assets/styles/services.css'
import '../assets/styles/services_responsive.css'
import Menu from '../components/partials/menu';
import { NavLink } from 'react-router-dom';
import Footer from '../components/partials/footer';
import Header from '../components/partials/header';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Services = () => {

  return (
    <div class="super_container">
      <Header />

      <div class="home" style={{ backgroundColor: "black" }}>
        <div class="home_background "
          style={{
            backgroundColor: "black",
            opacity: "35%",
            backgroundImage: `url(${images.elderCare})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",

          }}></div>
        <div class="home_container">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="home_content">
                  <div class="home_title"><span>Rhaegal Care</span> Services</div>
                  <div class="breadcrumbs">
                    <ul>
                      <li><a href="#">Home</a></li>
                      <li>Services</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <!-- Features --> */}

      <div class="features d-none">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="features_container d-flex flex-row flex-wrap align-items-start justify-content-between">

                {/* <!-- Feature --> */}
                <div class="feature">
                  <div class="feature_image"><img src={images.feature_1} alt="" /></div>
                  <div class="feature_content">
                    <div class="section_title">
                      <h2>We help you</h2>
                    </div>
                    <div class="feature_text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ante leo, finibus quis est ut,
                        tempor tincidunt ipsum. Nam consequat semp er solli citudin. Aliquam nec dapibus massa.</p>
                    </div>
                  </div>
                </div>

                {/* <!-- Feature --> */}
                <div class="feature">
                  <div class="feature_image"><img src={images.feature_2} alt="" /></div>
                  <div class="feature_content">
                    <div class="section_title">
                      <h2>Medicine for you</h2>
                    </div>
                    <div class="feature_text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ante leo, finibus quis est ut,
                        tempor tincidunt ipsum. Nam consequat semp er solli citudin. Aliquam nec dapibus massa.</p>
                    </div>
                  </div>
                </div>

                {/* <!-- Feature --> */}
                <div class="feature">
                  <div class="feature_image"><img src={images.feature_3} alt="" /></div>
                  <div class="feature_content">
                    <div class="section_title">
                      <h2>Amazing technology</h2>
                    </div>
                    <div class="feature_text">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ante leo, finibus quis est ut,
                        tempor tincidunt ipsum. Nam consequat semp er solli citudin. Aliquam nec dapibus massa.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Text Section --> */}

      <div class="text mt-5">
        <div class="container">
          <div class="row">

            {/* <!-- Text Section Image --> */}
            <div class="col-lg-6">
              <div class="text_section_image"><img src={images.text_section} alt="" /></div>
            </div>

            {/* <!-- Text Section Content --> */}
            <div class="col-lg-6">
              <div class="text_section_content">
                <div class="section_title">
                  <h2>1. Personalized Home Care</h2>
                </div>
                <div class="text_section_text">
                  <p>Our personalized home care services ensure that you or your loved one receive the care needed to
                    maintain independence
                    and comfort at home. Our caregivers assist with daily activities, including personal hygiene, meal
                    preparation,
                    medication management, and mobility support.</p>
                </div>

                <div class="section_title mt-5">
                  <h2>2. Live-In Care</h2>
                </div>
                <div class="text_section_text">
                  <p>For those requiring continuous support, our live-in care services offer round-the-clock assistance
                    from a dedicated
                    caregiver. This service provides peace of mind and ensures constant, compassionate care in the
                    familiar surroundings of
                    your home.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text mt-4 py-5" style={{ backgroundColor: "whitesmoke" }}>
        <div class="container">
          <div class="row">

            {/* <!-- Text Section Image --> */}
            <div class="col-lg-6">
              <div class="text_section_content">
                <div class="section_title">
                  <h2>3. Respite Care</h2>
                </div>
                <div class="text_section_text">
                  <p>We understand the importance of taking a break. Our respite care services provide temporary relief
                    for primary
                    caregivers, allowing them to rest and recharge while knowing their loved one is in safe, capable
                    hands.</p>
                </div>

                <div class="section_title mt-5">
                  <h2>4. Specialized Care</h2>
                </div>
                <div class="text_section_text">
                  <p>We offer specialized care services tailored to individuals with specific health conditions, such as
                    dementia,
                    Parkinson’s disease, or post-operative recovery. Our trained caregivers provide the expert support
                    needed to manage
                    these conditions effectively.</p>
                </div>
              </div>
            </div>

            {/* <!-- Text Section Content --> */}
            <div class="col-lg-6">
              <div class="text_section_image"><img src={images.text_section} alt="" /></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Call to action --> */}

      <div class="text mt-4 mb-4">
        <div class="container">
          <div class="row">

            {/* <!-- Text Section Image --> */}
            <div class="col-lg-6">
              <div class="text_section_image"><img src={images.text_section} alt="" /></div>
            </div>

            {/* <!-- Text Section Content --> */}
            <div class="col-lg-6">
              <div class="text_section_content">
                <div class="section_title">
                  <h2>5. Companionship Services</h2>
                </div>
                <div class="text_section_text">
                  <p>Our companionship services focus on providing emotional support and social interaction, reducing
                    feelings of loneliness
                    and isolation. Whether it’s a friendly chat, a walk in the park, or assistance with hobbies, we’re
                    here to enrich your
                    daily life.</p>
                </div>

                <div class="section_title mt-5">
                  <h2>6. Flexible Scheduling</h2>
                </div>
                <div class="text_section_text">
                  <p>Understanding that each client has unique needs, we offer flexible scheduling options, from a few
                    hours a week to
                    full-time care. Our services are designed to adapt to your evolving requirements, ensuring you receive
                    the right level
                    of support at all times.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      {/* <!-- Footer --> */}

      <Footer />
      <FloatingWhatsApp
        phoneNumber='+254749620643'
        accountName='Rhaegal Care'
        allowClickAway={true}
      />
    </div >
  )
}

export default Services