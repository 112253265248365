import React from 'react'
import images from '../assets/images/images';
import '../assets/styles/contact_responsive.css'
import '../assets/styles/contact.css'
import Footer from '../components/partials/footer';
import Header from '../components/partials/header';
import GetInTouch from '../components/getInTouch';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Contact = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div class="super_container">
      <Header />
      {/* <!-- Home --> */}

      <div class="home" style={{ backgroundColor: "black" }}>
        <div class="home_background "
          style={{
            backgroundColor: "black",
            opacity: "55%",
            backgroundImage: `url(${images.elderCare})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",

          }}></div>
        <div class="home_container">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="home_content">
                  <div class="home_title">Contact<span> Rhaegal Care</span> </div>
                  <div class="breadcrumbs">
                    <ul>
                      <li><a href="#">Home</a></li>
                      <li>Contact</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Contact --> */}

      <div class="contact">
        <div class="container">
          <div class="row">

            <GetInTouch />
          </div>
          <div class="row map_row">
            <div class="col">

              {/* <!-- Contact Map --> */}

              <div class="contact_map">

                {/* <!-- Google Map --> */}

                <div class="map">
                  {/* <div id="google_map" class="google_map">
                    <div class="map_container">
                      <div id="map">

                      </div>
                    </div>
                  </div> */}
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.626808308203!2d-0.11056392304456952!3d51.52006230977773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b4dd9bf3689%3A0xd06eb52133703417!2s4835%203a%2C%2034-35%20Hatton%20Garden%2C%20London%20EC1N%208DX%2C%20UK!5e0!3m2!1sen!2ske!4v1719945591582!5m2!1sen!2ske" width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

                {/* <!-- Working Hours --> */}
                <div class="box working_hours d-none d-md-block">
                  <div class="box_icon d-flex flex-column align-items-start justify-content-center">
                    <div style={{ width: "29px", height: "29px" }}>
                      <img src={images.alarmClock} alt="" /></div>
                  </div>
                  <div class="box_title">Working Hours</div>
                  <div class="working_hours_list">
                    <ul>
                      <li class="d-flex flex-row align-items-center justify-content-start">
                        <div>Monday – Friday</div>
                        <div class="ml-auto">8.00 – 19.00</div>
                      </li>
                      <li class="d-flex flex-row align-items-center justify-content-start">
                        <div>Saturday</div>
                        <div class="ml-auto">9.30 – 17.00</div>
                      </li>
                      <li class="d-flex flex-row align-items-center justify-content-start">
                        <div>Sunday</div>
                        <div class="ml-auto">9.30 – 15.00</div>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      {/* <!-- Footer --> */}

      <Footer />
      <FloatingWhatsApp
        phoneNumber='+254749620643'
        accountName='Rhaegal Care'
        allowClickAway={true}
      />
    </div >
  )
}

export default Contact