import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from './pages';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'
import About from './pages/about.jsx';
import Services from './pages/services.jsx';
import Contact from './pages/contact.jsx';
import Join from './pages/join.jsx';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/about" exact element={<About />}></Route>
          <Route path="/services" exact element={<Services />}></Route>
          <Route path="/contact" exact element={<Contact />}></Route>
          <Route path="/join" exact element={<Join />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}