import React from 'react'
import phonecall from '../assets/images/phone-call.svg'
import placeholder from '../assets/images/placeholder.svg'
import envelope from '../assets/images/envelope.svg'


const GetInTouch = () => {
  return (
    <div class="contact " style={{ backgroundColor: "white" }}>
    <div class="container">
      <div class="row">

        {/* <!-- Contact Info --> */}
        <div class="col-lg-6">
          <div class="section_title">
            <h2>Get in touch</h2>
          </div>
          <div class="contact_text">
            <p>Rhageal Care is a leading provider of domiciliary care services, dedicated to supporting adults in
              Liverpool and the
              surrounding areas. Our experienced and compassionate team is committed to delivering high-quality,
              personalized care in
              the comfort of your own home.</p>
          </div>
          <ul class="contact_about_list">
            <li>
              <div class="contact_about_icon"><img src={phonecall} alt="" /></div><span>+0151 271
                5767</span>
            </li>
            <li>
              <div class="contact_about_icon"><img src={phonecall} alt="" /></div><span>Alt:
                +07496240643</span>
            </li>
            <li>
              <div class="contact_about_icon"><img src={envelope} alt="" /></div>
              <span>info@rhaegalcare.co.uk</span>
            </li>
            <li>
              <div class="contact_about_icon"><img src={placeholder} alt="" /></div><span>141 Tripsen Close, Liverpool</span>
            </li>
          </ul>
        </div>

        {/* <!-- Contact Form --> */}
        <div class="col-lg-6 form_col">
          <div class="contact_form_container">
            <form action="#" id="contact_form" class="contact_form">
              <div class="row">
                <div class="col-md-6 input_col">
                  <div class="input_container input_name"><input type="text" class="contact_input" placeholder="Name"
                    required="required" /></div>
                </div>
                <div class="col-md-6 input_col">
                  <div class="input_container"><input type="email" class="contact_input" placeholder="E-mail"
                    required="required" /></div>
                </div>
              </div>
              <div class="input_container"><input type="text" class="contact_input" placeholder="Subject"
                required="required" /></div>
              <div class="input_container"><textarea class="contact_input contact_text_area" placeholder="Message"
                required="required"></textarea></div>
              <button class="button contact_button"><a href="#">send</a></button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}

export default GetInTouch