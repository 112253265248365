import React from 'react'
import images from '../assets/images/images'
import '../assets/styles/about_responsive.css'
import '../assets/styles/about.css'
import '../assets/styles/services.css'
import '../assets/styles/services_responsive.css'
import Footer from '../components/partials/footer'
import Values from '../components/values'
import Header from '../components/partials/header'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const About = () => {

  return (
    <div class="super_container">
      <Header />

      {/* <!-- Home --> */}

      <div class="home" style={{ backgroundColor: "black" }}>
        <div class="home_background "
          style={{
            backgroundColor: "black",
            opacity: "35%",
            backgroundImage: `url(${images.about})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",

          }}></div>
        <div class="home_container">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="home_content">
                  <div class="home_title">About <span>Rhaegal Care</span></div>
                  <div class="breadcrumbs">
                    <ul>
                      <li><a href="#">Home</a></li>
                      <li>About Rhaegal Care</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- About --> */}

      <div class="about">
        <div class="container">
          <div class="row">

            {/* <!-- About Content --> */}
            <div class="col-lg-8">
              <div class="section_title">
                <h2>About Us - Rhaegal Care</h2>
              </div>
              <div class="about_text">
                <p>At Rhageal Care, we are committed to delivering exceptional domiciliary care services that prioritize
                  the well-being and
                  independence of adults in their own homes. Our dedicated team offers personalized home care, live-in
                  care, and respite
                  care, tailored to meet the unique needs of each individual.</p>
                <p>We focus on enhancing quality of life, maintaining dignity, and fostering independence through
                  compassionate and
                  professional support. Our goal is to ensure that every client receives the highest standard of care.</p>
              </div>

              <div class="why-chose-us mt-5">
                <div class="section_title">
                  <h2>Why Choose Us - Rhageal Care</h2>
                </div>
                <p>
                  At Rhageal Care, we pride ourselves on providing exceptional domiciliary care services that prioritize
                  your well-being
                  and independence. Here are the key reasons to choose us:
                </p>
                <ol style={{ marginLeft: "20px" }}>
                  <li>
                    <strong>
                      Customized Care Plans:
                    </strong>
                    <p>
                      We develop tailored care plans to meet the unique needs and preferences of each individual,
                      ensuring the highest quality of personalized care.
                    </p>
                  </li>
                  <li>
                    <strong>
                      Expert Caregivers:
                    </strong>
                    <p>
                      Our team consists of highly trained, compassionate professionals dedicated to delivering the
                      best care possible.
                    </p>
                  </li>
                  <li>
                    <strong>
                      Flexible Service Options:
                    </strong>
                    <p>
                      We offer a range of services from occasional support to full-time live-in care, designed to
                      adapt to your evolving
                      needs.
                    </p>
                  </li>
                  <li>
                    <strong>
                      Unwavering Commitment to Quality:
                    </strong>
                    <p>
                      We are dedicated to enhancing the quality of life, preserving dignity, and promoting
                      independence through our
                      exceptional care standards.
                    </p>
                  </li>
                  <li>
                    <strong>
                      Local Knowledge and Expertise:
                    </strong>
                    <p>
                      Serving Liverpool and the surrounding areas, we leverage our deep understanding of the local
                      community and resources to
                      provide superior care.
                    </p>
                  </li>
                </ol>
              </div>

            </div>

            {/* <!-- Boxes --> */}
            <div class="col-lg-4 boxes_col">



              {/* <!-- Box --> */}
              <div class="box box_appointments">
                {/* <!-- <div class="box_icon d-flex flex-column align-items-start justify-content-center">
                  <div style="width: 29px; height:29px;"><img src="images/phone-call.svg" alt=""></div>
                </div> --> */}
                <div class="box_title">Our Vision</div>
                <div class="box_text pb-4">Our vision is to be the most trusted and respected care provider in Liverpool, known
                  for our commitment to excellence,
                  compassion, and innovation in care. We strive to set the standard for quality in-home care services,
                  ensuring every
                  client feels valued.
                </div>
              </div>

              {/* <!-- Box --> */}
              <div class="box box_emergency">
                {/* <!-- <div class="box_icon d-flex flex-column align-items-start justify-content-center">
                  <div style="width: 37px; height:37px; margin-left:-4px;"><img src="images/bell.svg" alt=""></div>
                </div> --> */}
                <div class="box_title">Our Mission</div>
                <div class="box_emergency_text">Our mission is to enhance the quality of life for our clients by providing
                  exceptional, person-centered care. We aim to
                  foster independence, preserve dignity, and offer support that is tailored to the unique needs of each
                  individual.</div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* <!-- Testimonials --> */}

      <div class="testimonials d-none" style={{ backgroundColor: "black" }}>
        <div class="testimonials_background"
          style={{
            backgroundColor: "black",
            opacity: "40%",
            backgroundImage: `url(${images.mainBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",
          }}>
        </div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section_title section_title_light">
                <h2>Patient Testimonials</h2>
              </div>
            </div>
          </div>
          <div class="row test_row">

            {/* <!-- Testimonial --> */}
            <div class="col-lg-6 test_col">
              <div class="testimonial">
                <div class="test_icon d-flex flex-column align-items-center justify-content-center"><img
                  src={images.quote} alt="" /></div>
                <div class="test_text">Rhaegal Care provides exceptional care. The staff is compassionate and
                  professional, creating a
                  supportive environment where residents thrive. The facility is clean and inviting, with engaging
                  activities tailored to
                  enrich their lives. Excellent communication keeps us reassured. Highly recommend!</div>
                <div class="test_author d-flex flex-row align-items-center justify-content-start">
                  <div class="test_author_image">
                    <div><img src={images.test_1} alt="" /></div>
                  </div>
                  <div class="test_author_name"><a href="#">Claire Smith</a>, <span>Client</span></div>
                </div>
              </div>
            </div>

            {/* <!-- Testimonial --> */}
            <div class="col-lg-6 test_col">
              <div class="testimonial">
                <div class="test_icon d-flex flex-column align-items-center justify-content-center"><img
                  src={images.quote} alt="" /></div>
                <div class="test_text">Choosing Rhaegal Care was a blessing. The staff's care and dedication create a
                  warm,
                  supportive
                  environment. The facility is clean and welcoming, with enriching activities tailored to residents'
                  needs. Communication
                  is excellent, providing peace of mind. Highly recommended for exceptional eldercare.</div>
                <div class="test_author d-flex flex-row align-items-center justify-content-start">
                  <div class="test_author_image">
                    <div><img src={images.test_2} alt="" /></div>
                  </div>
                  <div class="test_author_name"><a href="#">Mick Williams</a>, <span>Client</span></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>




      {/* <!-- Our Values --> */}
      <Values />


      {/* <!-- Footer --> */}

      <Footer />
      <FloatingWhatsApp
        phoneNumber='+254749620643'
        accountName='Rhaegal Care'
        allowClickAway={true}
      />
    </div>
  )
}

export default About