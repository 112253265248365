import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  backgroundColor: '#6610f2'
});

const ProtectingAdultsAndChildren = (props) => {
  const [dbsc, setDbsc] = useState(null);
  const [policeEnquiries, setPoliceEnquiries] = useState(null);
  const [dbscNumber, setDbscNumber] = useState('');
  const [activeUpdateService, setActiveUpdateService] = useState(null);

  const handleSubmit = async () => {
    const payload = {
      id: uuidv4(),
      dbsc: dbsc === 'Yes',
      dbscNumber: parseInt(dbscNumber, 10),
      activeUpdateService: activeUpdateService === 'Yes',
      personalDetailId: props.newUser // Replace with actual personalDetailId
    };

    try {
      const response = await fetch('https://api.rhaegalcare.co.uk/api/Employees/AddVulnarabbilities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Success:', responseData);

      props.next(); // Call the next function passed in props
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="col-lg-12 form_col ">
      <div className="contact_form_container">
        <form action="#" id="contact_form" className="contact_form px-5">
          {/* <p>Do you have a current Disclosure and Barring Service certificate? (Required)</p>
          <div className="mb-3">
            <Form.Check
              inline
              label="Yes"
              name="dbsc"
              type="radio"
              id="dbsc-yes"
              value="Yes"
              checked={dbsc === 'Yes'}
              onChange={(e) => setDbsc(e.target.value)}
            />
            <Form.Check
              inline
              label="No"
              name="dbsc"
              type="radio"
              id="dbsc-no"
              value="No"
              checked={dbsc === 'No'}
              onChange={(e) => setDbsc(e.target.value)}
            />
          </div> */}

          <div className="my-3">
            <p>Do you have a current Disclosure and Barring Service certificate? (Required)</p>

            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              className=' d-flex w-25 '
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => console.log(event.target.files)}

              />
            </Button>
          </div>

          <p> Are you aware of any police enquiries undertaken following allegations made against you, which may have a bearing on your suitability for this post? (Required)</p>
          <div className="mb-3">
            <Form.Check
              inline
              label="Yes"
              name="policeEnquiries"
              type="radio"
              id="policeEnquiries-yes"
              value="Yes"
              checked={policeEnquiries === 'Yes'}
              onChange={(e) => setPoliceEnquiries(e.target.value)}
            />
            <Form.Check
              inline
              label="No"
              name="policeEnquiries"
              type="radio"
              id="policeEnquiries-no"
              value="No"
              checked={policeEnquiries === 'No'}
              onChange={(e) => setPoliceEnquiries(e.target.value)}
            />
          </div>



          <p>Are you subscribed to the online update service? (Required)</p>
          <div className="mb-3">
            <Form.Check
              inline
              label="Yes"
              name="activeUpdateService"
              type="radio"
              id="activeUpdateService-yes"
              value="Yes"
              checked={activeUpdateService === 'Yes'}
              onChange={(e) => setActiveUpdateService(e.target.value)}
            />
            <Form.Check
              inline
              label="No"
              name="activeUpdateService"
              type="radio"
              id="activeUpdateService-no"
              value="No"
              checked={activeUpdateService === 'No'}
              onChange={(e) => setActiveUpdateService(e.target.value)}
            />
          </div>

          <div className="col-md-12 input_col">
            <div className="input_container input_name">
              <TextField
                id="filled-basic"
                label="Current online DBS Number"
                variant="filled"
                name="dbscNumber"
                value={dbscNumber}
                onChange={(e) => setDbscNumber(e.target.value)}
                className='w-100'
                size='small'
                required
              />
              {/* <input
                type="text"
                className="contact_input"
                placeholder="Current DBS certificate Number"
                name="dbscNumber"
                value={dbscNumber}
                onChange={(e) => setDbscNumber(e.target.value)}
                required
              /> */}
            </div>
          </div>


          <div className="d-flex justify-content-between pt-4">
            <button type="button" className="button contact_button" onClick={props.prev}>
              <a href="#">Back</a>
            </button>
            <button type="button" className="button contact_button" onClick={() => { handleSubmit(); props.next(); }}>
              <a href="#">Next</a>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProtectingAdultsAndChildren;
