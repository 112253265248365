import React from 'react'
import images from '../assets/images/images';
import '../assets/styles/main_styles.css'
import '../assets/styles/responsive.css'
import Carousel from 'react-bootstrap/Carousel';
import Menu from '../components/partials/menu';
import { NavLink } from 'react-router-dom';
import Footer from '../components/partials/footer';
import Values from '../components/values';
import Header from '../components/partials/header';
import GetInTouch from '../components/getInTouch';
import { FloatingWhatsApp } from 'react-floating-whatsapp';


const Home = () => {
  return (
    <div class="super_container">
      {/* <!-- Header --> */}

      <Header />


      {/* <!-- Home --> */}
      <Carousel controls={false} className='d-none d-md-block'>
        <Carousel.Item>
          <img src={images.mainBackground} alt="" srcset="" className='img-fluid' />
          {/* <ExampleCarouselImage text="First slide" /> */}
          <Carousel.Caption style={{ height: '350px', }}>

            <div class="container " style={{
              marginTop: "-30px"
            }}>
              <div class="row">
                <div class="col">
                  <div class="" >
                    <div class="home_title">
                      <h1>We Care For You</h1>
                    </div>
                    <div class="home_text">
                      <p>We pride ourselves on providing exceptional domiciliary care services that prioritize your
                        well-being and independence.
                      </p>
                    </div>
                    <div class="button home_button">
                      <NavLink to='/about'>read more</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>
      {/* <!-- 3 Boxes --> */}

      <div className="phoneCover d-md-none">
        <img src={images.happyElders} alt="" srcset="" className='img-fluid' />
      </div>

      <div class="boxes"
        style={{
          marginTop: -90,
          paddingBottom: 100
        }}
      >
        <div class="container">
          <div class="row">

            {/* <!-- Box --> */}
            <div class="col-lg-4 box_col">
              <div class="box box_appointments">
                <div class="box_icon d-flex flex-column align-items-start justify-content-center">
                  <div style={{
                    width: "29px",
                    height: "29px"
                  }}><img src={images.alarmClock} alt="" /></div>
                </div>
                <div class="box_title">Working Hours</div>
                <div class="working_hours_list">
                  <ul>
                    <li class="d-flex flex-row align-items-center justify-content-start">
                      <div>Monday – Friday </div>
                      <div class="ml-auto ps-2"> 8.00 – 19.00</div>
                    </li>
                    <li class="d-flex flex-row align-items-center justify-content-start">
                      <div>Saturday</div>
                      <div class="ml-auto ps-2">9.30 – 17.00</div>
                    </li>
                    <li class="d-flex flex-row align-items-center justify-content-start">
                      <div>Sunday</div>
                      <div class="ml-auto ps-2">9.30 – 15.00</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <!-- Box --> */}
            <div class="col-lg-4 box_col">
              <div class="box box_appointments">
                <div class="box_icon d-flex flex-column align-items-start justify-content-center">
                  <div style={{
                    width: "29px",
                    height: "29px"
                  }}><img src={images.phoneCall} alt="" /></div>
                </div>
                <div class="box_title">Appointments</div>
                <div class="box_text">Our highly trained caregivers assist with daily activities and offer comprehensive support to ensure our clients enjoy a high quality of life wile fostering wellbeing and independencess</div>
              </div>
            </div>

            {/* <!-- Box --> */}
            <div class="col-lg-4 box_col">
              <div class="box box_emergency">
                <div class="box_icon d-flex flex-column align-items-start justify-content-center">
                  <div style={{
                    width: "37px",
                    height: "37px",
                    marginLeft: "-4px"
                  }}><img src={images.bell} alt="" /></div>
                </div>
                <div class="box_title">Get In Touch</div>
                <div class="box_phone">Call +0151 271 5767</div>
                <div class="box_emergency_text">141 Trispen Close, Liverpool L26 7YS</div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* <!-- About --> */}

      <div class="about">
        <div class="container">
          <div class="row row-lg-eq-height">

            {/* <!-- About Content --> */}
            <div class="col-lg-7">
              <div class="about_content">
                <div class="section_title">
                  <h2>About Us</h2>
                </div>
                <div class="about_text ">
                  <p>
                    Rhageal Care is a leading provider of domiciliary care services, dedicated to supporting adults in
                    Liverpool and the surrounding areas. Our experienced and compassionate team is committed to delivering
                    high-quality, personalized care in the comfort of your own home.
                  </p>
                  <p>
                    Our dedicated team offers personalized home care, live-in care, and respite care, tailored to meet the
                    unique needs of each individual.

                    We focus on enhancing quality of life, maintaining dignity, and fostering independence through
                    compassionate and professional support. Our goal is to ensure that every client receives the highest
                    standard of care.

                  </p>
                </div>
                <div class="button about_button">
                  <NavLink to='/about'>read more</NavLink>
                </div>
              </div>
            </div>

            {/* <!-- About Image --> */}
            <div class="col-lg-5">
              <div class="about_image"><img src={images.about} alt="" /></div>
            </div>
          </div>
        </div>
      </div>

      <div class="services" style={{ backgroundColor: "whitesmoke" }}>
        <div class="container">
          <div class="section_title pb-4">
            <h2>Our Services</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-12 ">
              {/* <!-- Feature --> */}
              <div class="feature_image"><img src={images.feature_1} alt="" /></div>
              <div class="feature_content">
                <div class="section_title">
                  <h2>Personalized Home Care</h2>
                </div>
                <div class="feature_text">
                  <p>Our personalized home care services ensure that you or your loved one receive the care needed
                    to
                    maintain independence...
                  </p><br />
                  <button class="button contact_button"><NavLink to='/services'>Read More</NavLink></button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              {/* <!-- Feature --> */}
              <div class="feature_image"><img src={images.feature_2} alt="" /></div>
              <div class="feature_content">
                <div class="section_title">
                  <h2>Companionship Services</h2>
                </div>
                <div class="feature_text">
                  <p>Our companionship services focus on providing emotional support and social interaction,
                    reducing
                    feelings of loneliness...
                  </p>
                  <br />
                  <button class="button contact_button"><NavLink to='/services'>Read More</NavLink></button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              {/* <!-- Feature --> */}
              <div class="feature_image"><img src={images.feature_3} alt="" /></div>
              <div class="feature_content">
                <div class="section_title">
                  <h2>Live-In Care</h2>
                </div>
                <div class="feature_text">
                  <p class="pb-2"></p>

                  <p>For those requiring continuous support, our live-in care services offer round-the-clock
                    assistance from a dedicated...
                  </p>
                  <br />
                  <button class="button contact_button"><NavLink to='/services'>Read More</NavLink></button>
                </div>
              </div>
            </div>
          </div>

          <div class="col mt-5">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <div class="feature_image"><img src={images.feature_4} alt="" /></div>
                <div class="feature_content">
                  <div class="section_title">
                    <h2>Specialized Care</h2>
                  </div>
                  <div class="feature_text">
                    <p>We offer specialized care services tailored to individuals with specific health conditions,
                      such
                      as dementia...
                    </p>
                    <br />
                    <button class="button contact_button"><NavLink to='/services'>Read More</NavLink></button>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-12">
                <div class="feature_image"><img src={images.feature_5} alt="" /></div>
                <div class="feature_content">
                  <div class="section_title">
                    <h2>Respite Care</h2>
                  </div>
                  <div class="feature_text">
                    <p>We understand the importance of taking a break. Our respite care services provide temporary
                      relief for primary...
                    </p>
                    <br />
                    <button class="button contact_button"><NavLink to='/services'>Read More</NavLink></button>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-12">
                <div class="feature_image"><img src={images.feature_6} alt="" /></div>
                <div class="feature_content">
                  <div class="section_title">
                    <h2>Flexible Scheduling</h2>
                  </div>
                  <div class="feature_text">
                    <p>Understanding that each client has unique needs, we offer flexible scheduling options, from a
                      few
                      hours a week to...
                    </p>
                    <br />
                    <button class="button contact_button"><NavLink to='/services'>Read More</NavLink></button>

                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      {/* <!-- Departments --> */}

      {/* <!-- Our Values --> */}
      <Values />


      <div class="services" style={{ backgroundColor: "whitesmoke" }}>
        <div class="container">

          <div class="row services_row">
            <div class="col-lg-6 col-sm-12">
              <img src={images.resumeBg} alt="" srcset="" class="img-fluid" />
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="section_title">
                <h2>Work With Us</h2>
              </div>
              <p>
                Join our team and be part of a dynamic workforce dedicated to innovation and excellence. At Rhaegal
                Care, we value
                creativity, collaboration, and a commitment to exceeding expectations. Whether you're a seasoned
                professional or just
                starting your career, we offer opportunities to grow and thrive in a supportive environment.
              </p>
              <p>
                Discover
                how your skills
                can make a difference and contribute to our mission. Explore our
                current openings and
                embark on a rewarding journey with us today!
              </p>

              <div class="mt-5">
                <button class="button contact_button"><NavLink to='/join'>Apply</NavLink></button>
              </div>

            </div>

          </div>
        </div>
      </div>

      {/* <!-- contact-form --> */}
      <GetInTouch />




      {/* <!-- Footer --> */}

      <Footer />

      <FloatingWhatsApp
        phoneNumber='+254749620643'
        accountName='Rhaegal Care'
        allowClickAway={true}
      />
    </div >

  )
}

export default Home