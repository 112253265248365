import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import { v4 as uuidv4 } from 'uuid';

const Rehabilitation = (props) => {
  const [convictions, setConvictions] = useState(null);
  const [policeEnquiries, setPoliceEnquiries] = useState(null);

  const handleSubmit = async () => {
    if (convictions === null || policeEnquiries === null) {
      alert("Please answer all questions.");
      return;
    }

    const payload = {
      id: uuidv4(),
      convictions: convictions === 'yes',
      policeEnquiries: policeEnquiries === 'yes',
      personalDetailId: props.newUser // Replace with actual personalDetailId
    };

    try {
      const response = await fetch('https://api.rhaegalcare.co.uk/api/Employees/AddCriminalRecord', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Success:', responseData);

      props.next(); // Call the next function passed in props
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="col-lg-12 form_col">
      <div className="contact_form_container">
        <form action="#" id="contact_form" className="contact_form px-5">

          <Form>
            <p>Do you have any convictions that are unspent under the rehabilitation of offenders act 1974?
              (Required)</p>
            {['radio'].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="convictions"
                  type={type}
                  id={`inline-${type}-1`}
                  onChange={() => setConvictions('yes')}
                />
                <Form.Check
                  inline
                  label="No"
                  name="convictions"
                  type={type}
                  id={`inline-${type}-2`}
                  onChange={() => setConvictions('no')}
                />
              </div>
            ))}
            <p>Are you aware of any police enquiries undertaken following allegations made against you, which may have a bearing on your suitability for this post
              (Required)</p>
            {['radio'].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Yes"
                  name="policeEnquiries"
                  type={type}
                  id={`inline-${type}-1`}
                  onChange={() => setPoliceEnquiries('yes')}
                />
                <Form.Check
                  inline
                  label="No"
                  name="policeEnquiries"
                  type={type}
                  id={`inline-${type}-2`}
                  onChange={() => setPoliceEnquiries('no')}
                />
              </div>
            ))}
          </Form>

          <div className="d-flex justify-content-between pt-4">
            <button className="button contact_button" type="button" onClick={props.prev}>
              <a href="#">Back</a>
            </button>
            <button className="button contact_button" type="button" onClick={()=>{handleSubmit();props.next();}}>
              <a href="#">Next</a>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Rehabilitation;
