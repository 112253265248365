import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  backgroundColor:'#6610f2'
});

const TrainingAndDevelopment = (props) => {
  const [trainingSections, setTrainingSections] = useState([{ id: Date.now(), programme: '', duration: '' }]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedTrainingSections = [...trainingSections];
    updatedTrainingSections[index][name] = value;
    setTrainingSections(updatedTrainingSections);
  };

  const addTrainingSection = () => {
    setTrainingSections([...trainingSections, { id: Date.now(), programme: '', duration: ''  }]);
  };

  const trainings = [
    {
      file:'',
      name:'Emergency First Aid at work'
    },
    {
      file:'',
      name:'Mental Capacity Act & Deprivation of Liberty'
    },
    {
      file:'',
      name:'Manual Handling Theory'
    },
    {
      file:'',
      name:'Food Hygine Level 2'
    },
    {
      file:'',
      name:'Health & Safety incl. Risk Assessment'
    },
    {
      file:'',
      name:'Equality and Diversity'
    },
    {
      file:'',
      name:'Fire'
    },
    {
      file:'',
      name:'Infection Control'
    },
    {
      file:'',
      name:'Medical Administration'
    },
    {
      file:'',
      name:'Safeguarding of Vulnarable Adults'
    },

  ]
  const handleSubmit = async () => {
    const payload = trainingSections.map(section => ({
      id: uuidv4(),
      programme: section.programme,
      duration: section.duration,
      personalDetailId: props.newUser // Replace with actual personalDetailId
    }));

    try {
      const response = await fetch('https://api.rhaegalcare.co.uk/api/Employees/AddTrainingHistory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Success:', responseData);

      props.next(); // Call the next function passed in props
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="col-lg-12 form_col">
      <div className="contact_form_container">
      <div className="py-3 px-5">
          <h4>Mandatory Required Trainings</h4>
          <hr />
        </div>
        <form id="contact_form" className="contact_form px-5 mb-3">
            {
              trainings.map((item,key)=>
                <Form.Group controlId="formFileGovernmentPhoto" className="mb-3">
                  <Form.Label>{key+1}. {item.name}  (Required)</Form.Label>
                  <div className="row">
                    <div className="col-lg-6  ">
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        className=' d-flex w-100 py-3 mt-2'
                      >
                        Upload file
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(event) => console.log(event.target.files)}
                          
                        />
                      </Button>
                    </div>
                    <div className="col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker label="Expiry Date" views={['month', 'year']}  />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                  </div>
            
                 
                </Form.Group>
              )
            }
         

          <div className="d-flex justify-content-between pt-4 mt-4">
            <button type="button" className="button contact_button" onClick={props.prev}>
              <a href="#">Back</a>
            </button>
            <button type="button" className="button contact_button" onClick={()=>{handleSubmit();props.next();}}>
              <a href="#">Next</a>
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default TrainingAndDevelopment;
