import React from 'react'
import images from '../assets/images/images';
import '../assets/styles/contact_responsive.css'
import { Step, Stepper } from 'react-form-stepper';
import '../assets/styles/contact.css'
import Menu from '../components/partials/menu';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Registeration from '../components/registration';
import Footer from '../components/partials/footer';
import Header from '../components/partials/header';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Join = () => {

  return (
    <div class="super_container">
      <Header />

      {/* <!-- Home --> */}
      <div class="home" style={{ backgroundColor: "black" }}>
        <div class="home_background "
          style={{
            backgroundColor: "black",
            opacity: "55%",
            backgroundImage: `url(${images.about})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",

          }}></div>
        <div class="home_container">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="home_content">
                  <div class="home_title">Join<span> Rhaegal Care</span> </div>
                  <div class="breadcrumbs">
                    <ul>
                      <li><a href="/">Home</a></li>
                      <li>Join</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Contact --> */}

      <div class="contact">
        <div class="container">
          <div class="row">

            {/* <!-- Contact Form --> */}
            <Registeration />

          </div>

        </div>
      </div>

      {/* <!-- Footer --> */}

      <Footer />
      <FloatingWhatsApp
        phoneNumber='+254749620643'
        accountName='Rhaegal Care'
        allowClickAway={true}
      />
    </div >
  )
}

export default Join