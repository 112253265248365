import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const References = ({ refereeSections, newUser, prev, next }) => {
  const [referees, setReferees] = useState(refereeSections.map(() => ({
    name: '',
    title: '',
    relationship: '',
    organization: '',
    telephone: '',
    emailAddress: '',
    willingToApproach: true
  })));

  const handleInputChange = (index, field, value) => {
    const newReferees = referees.map((referee, i) =>
      i === index ? { ...referee, [field]: value } : referee
    );
    setReferees(newReferees);
  };

  const addReferenceSection = () => {
    setReferees([...referees, {
      name: '',
      title: '',
      relationship: '',
      organization: '',
      telephone: '',
      emailAddress: '',
      willingToApproach: true
    }]);
  };

  const handleSubmit = async () => {
    const payload = referees.map((referee, index) => ({
      id: uuidv4(),
      name: referee.name,
      title: referee.title,
      relationship: referee.relationship,
      organization: referee.organization,
      telephone: referee.telephone,
      emailAddress: referee.emailAddress,
      willingToApproach: referee.willingToApproach,
      personalDetailId: newUser // Replace with actual personalDetailId
    }));

    try {
      const response = await fetch('https://api.rhaegalcare.co.uk/api/Employees/AddReference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Success:', responseData);

      next(); // Call the next function passed in props
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="col-lg-12 form_col">
      <div className="contact_form_container">
        <form id="contact_form" className="contact_form px-5">
          {referees.map((referee, index) => (
            <div className="w-100" key={index}>
              <h3>Referee {index + 1}</h3>
              <hr />
              <div className="row">
                <div className="col-md-6 input_col">
                  <div className="input_container input_name">
                    <TextField
                      id="filled-basic"
                      label="Full Name"
                      variant="filled"

                      value={referee.name}
                      onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      className='w-100'
                      size='small'
                      required
                    />
                    {/* <input
                      type="text"
                      className="contact_input"
                      placeholder="Full Name"
                      value={referee.name}
                      onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      required
                    /> */}
                  </div>
                </div>
                <div className="col-md-6 input_col">
                  <div className="input_container">
                    <TextField
                      id="filled-basic"
                      label="Position (Job Title)"
                      variant="filled"

                      value={referee.title}
                      onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                      className='w-100'
                      size='small'
                      required
                    />
                    {/* <input
                      type="text"
                      className="contact_input"
                      placeholder="Position (Job Title) (Required)"
                      value={referee.title}
                      onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                      required
                    /> */}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6 input_col">
                  <div className="input_container input_name">
                    {/* <input
                      type="text"
                      className="contact_input"
                      placeholder="Work Relationship (Required)"
                      value={referee.relationship}
                      onChange={(e) => handleInputChange(index, 'relationship', e.target.value)}
                      required
                    /> */}
                    <TextField
                      id="filled-basic"
                      label="Work Relationship "
                      variant="filled"

                      value={referee.relationship}
                      onChange={(e) => handleInputChange(index, 'relationship', e.target.value)}
                      className='w-100'
                      size='small'
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 input_col">
                  <div className="input_container">
                    <TextField
                      id="filled-basic"
                      label="Organisation "
                      variant="filled"

                      value={referee.organization}
                      onChange={(e) => handleInputChange(index, 'organization', e.target.value)}
                      className='w-100'
                      size='small'
                      required
                    />
                    {/* <input
                      type="text"
                      className="contact_input"
                      placeholder="Organisation (Required)"
                      value={referee.organization}
                      onChange={(e) => handleInputChange(index, 'organization', e.target.value)}
                      required
                    /> */}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6 input_col">
                  <div className="input_container input_name">
                    <TextField
                      id="filled-basic"
                      label="Telephone Number "
                      variant="filled"

                      value={referee.telephone}
                      onChange={(e) => handleInputChange(index, 'telephone', e.target.value)}
                      className='w-100'
                      size='small'
                      required
                    />
                    {/* <input
                      type="text"
                      className="contact_input"
                      placeholder="Telephone Number (Required)"
                      value={referee.telephone}
                      onChange={(e) => handleInputChange(index, 'telephone', e.target.value)}
                      required
                    /> */}
                  </div>
                </div>
                <div className="col-md-6 input_col">
                  <div className="input_container">
                    <TextField
                      id="filled-basic"
                      label="Email "
                      variant="filled"
                      value={referee.emailAddress}
                      onChange={(e) => handleInputChange(index, 'emailAddress', e.target.value)}
                      className='w-100'
                      size='small'
                      required
                    />
                    {/* <input
                      type="email"
                      className="contact_input"
                      placeholder="Email (Required)"
                      value={referee.emailAddress}
                      onChange={(e) => handleInputChange(index, 'emailAddress', e.target.value)}
                      required
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-center pt-4">
            <button type="button" className="button contact_button" onClick={addReferenceSection}>
              <a>+ Add Another Referee</a>
            </button>
          </div>
          <div className="d-flex justify-content-between pt-4">
            <button type="button" className="button contact_button" onClick={prev}>
              <a href="#">Back</a>
            </button>
            <button className="button contact_button" type="button" onClick={() => { handleSubmit(); next(); }}>
              <a href="#">Next</a>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default References;
