import React from 'react'
import images from '../../assets/images/images'
import { NavLink } from 'react-router-dom'
import phonecall from '../../assets/images/phone-call.svg'
import placeholder from '../../assets/images/placeholder.svg'
import envelope from '../../assets/images/envelope.svg'
import facebook from '../../assets/images/social/facebook.svg'
import instagram from '../../assets/images/social/instagram.svg'

const Footer = () => {

  const iconstyle = {
    backgroundColor: '#fcb900 ',
    padding: '5px',
    borderRadius: '50%'
  }

  return (
    <div>
      {/* <!-- Call to action --> */}
      < div class="cta" style={{ backgroundColor: "black" }
      }>
        <div class="cta_background "
          style={{
            backgroundColor: "black",
            opacity: "40%",
            backgroundImage: `url(${images.home_background_1})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",
          }}>

          </div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="cta_content text-center">
                <h2>Need Care Service?</h2>
                <p>contact the team for free no obligation care assessment</p>
                <div class="button cta_button"><NavLink to="/contact"><a >request a plan</a></NavLink></div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <footer class="footer">
        <div class="footer_container">
          <div class="container">
            <div class="row">

              {/* <!-- Footer - About --> */}
              <div class="col-lg-4 footer_col">
                <div class="footer_column">
                  <div class="footer-logo d-flex justify-content-center pb-1" style={{ backgroundColor: "#fcb900" }}>
                    <img src={images.logo} alt="" srcset="" style={{ width: "100%" }} />
                  </div>

                  <h4 className='mt-3 text-white text-center'>VAT Number: 471 6858 56</h4>
                </div>
              </div>

              {/* <!-- Footer - Links --> */}
              <div class="col-lg-4 footer_col">
                <div class="footer_links footer_column">
                  <div class="footer_title">Useful Links</div>
                  <ul>
                    <li><a href="#">Testimonials</a></li>
                    <li><a href="#">FAQ</a></li>
                    <li><a href="#">Apply for a Job</a></li>
                    <li><a href="#">Terms & Conditions</a></li>
                    <li><a href="#">Our Partners</a></li>
                    <li><a href="#">Services</a></li>
                    <li><a href="#">About us</a></li>
                    <li><a href="#">Contact</a></li>
                  </ul>
                </div>
              </div>

              {/* <!-- Footer - News --> */}
              <div class="col-lg-4 footer_col">
                <div class="footer_news footer_column">
                  <div class="footer_title">Get In Touch</div>
                  <ul class="footer_about_list">


                    <li>
                      <div class="footer_about_icon"><img src={phonecall} alt="" /></div><span>0151 271
                        5767</span>
                    </li>
                    <li>
                      <div class="footer_about_icon"><img src={phonecall} alt="" /></div><span>Alt:
                        07496240643</span>
                    </li>

                    <li>
                      <div class="footer_about_icon"><img src={envelope} alt="" /></div>
                      <span>info@rhaegalcare.co.uk</span>
                    </li>
                    <li>
                      <div class="footer_about_icon"><img src={placeholder} alt="" /></div><span>
                       141 Tripsen Close, Liverpool</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col">
                <div
                  class="copyright_content d-flex flex-lg-row flex-column align-items-lg-center justify-content-between">
                  <div class="cr">
                    Copyright &copy;
                    <script>document.write(new Date().getFullYear());</script> All rights reserved 
                    </div>
                  <div class="footer_social ml-lg-auto">
                    <ul>
                      <li style={iconstyle}><a href="https://www.facebook.com/share/yWv8626wGw4RbxyT/?mibextid=LQQJ4d"><img src={facebook} alt="" srcset="" className='img-fluid' /></a></li>
                      <li style={iconstyle}><a href="https://www.instagram.com/rhaegalcare/"><img src={instagram} alt="" srcset="" className='img-fluid' /></a></li>

                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </footer >
    </div>
  )
}

export default Footer